import { defineAsyncComponent } from "vue";
import { SetupCalendar } from "v-calendar";
import { plugin as VueTippy } from "vue-tippy";
import { START_LOCATION } from "vue-router";

import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue";
import { useUserSession } from "/@src/lib/stores/userSession";
import { registerScrollSpy } from "vue3-scroll-spy";
import Vue3TouchEvents from "vue3-touch-events";
import background from "/@src/lib/directives/background";
import tooltip from "/@src/lib/directives/tooltip";
import focus from "/@src/lib/directives/focus";

import type { JarveatAppContext } from "./app";

// Lazy load aditional components
export async function registerGlobalComponents({ app }: JarveatAppContext) {
    app.use(SetupCalendar, {});
    app.use(VueTippy, {
        component: "Tippy",
        defaultProps: {
            theme: "light",
        },
    });
    app.component("CollapseTransition", CollapseTransition);

    app.component(
        // eslint-disable-next-line vue/multi-word-component-names
        "Multiselect",
        defineAsyncComponent({
            loader: () => import("@vueform/multiselect").then((mod) => mod.default),
            delay: 0,
            suspensible: false,
        })
    );
    app.component(
        // eslint-disable-next-line vue/multi-word-component-names
        "Slider",
        defineAsyncComponent({
            loader: () => import("@vueform/slider").then((mod) => mod.default),
            delay: 0,
            suspensible: false,
        })
    );
    app.component(
        "VCalendar",
        defineAsyncComponent({
            loader: () => import("v-calendar").then((mod) => mod.Calendar),
            delay: 0,
            suspensible: false,
        })
    );
    app.component(
        "VDatePicker",
        defineAsyncComponent({
            loader: () => import("v-calendar").then((mod) => mod.DatePicker),
            delay: 0,
            suspensible: false,
        })
    );

    app.component(
        "VueSlider",
        defineAsyncComponent({
            loader: () => import("vue-slider-component").then((mod) => mod.default),
            delay: 0,
            suspensible: false,
        })
    );

    app.use(Vue3TouchEvents);

    app.directive("background", background);
    app.directive("tooltip", tooltip);
    app.directive("focus", focus);

    /*app.use(VueTelInput, { 
        mode: "international", 
        defaultCountry: "FR", 
        enabledCountryCode: true, 
        preferredCountries: ["FR"], 
        validCharactersOnly: true, 
        autocomplete: "off", 
        dropdownOptions: { 
            showDialCodeInList: true, 
            showDialCodeInSelection: true, 
            showFlags: true 
        }, 
        inputOptions: { 
            placeholder: "Enter Mobile Number", 
            required: false,
            showDialCode: true
        } 
    });*/

    registerScrollSpy(app);
}

/**
 * Here we are setting up two router navigation guards
 *
 * We can add meta to pages either by declaring them manualy in the
 * routes declaration (see /@src/router.ts)
 * or by adding a <route> tag into .vue files (see /@src/pages/sidebar/dashboards.ts)
 *
 * <route lang="yaml">
 * meta:
 *   requiresAuth: true
 * </route>
 *
 * <script setup lang="ts">
 *  // TS script
 * </script>
 *
 * <template>
 *  // HTML content
 * </template>
 */
export function registerRouterNavigationGuards({ router }: JarveatAppContext) {
    console.log(router);
    router.onError((error) => {
        console.error(error);
    });
    router.beforeEach(async (to, from) => {
        const userSession = useUserSession();

        const isLoggedIn = await userSession.isLoggedIn();

        if (from === START_LOCATION && isLoggedIn) {
            // 1. If the name is not set, it means we are navigating to the first page
            // and we are logged in, so we should check user information from the server
            try {
                if (to.meta.roles && !(await userSession.hasOneRole(to.meta.roles))) {
                    return {
                        // Will follow the redirection set in /@src/pages/auth/index.vue
                        name: "auth-login",
                        // save the location we were at to come back later
                        query: { redirect: to.fullPath },
                    };
                }
            } catch (err) {
                console.error(err);
                // delete stored token if it fails
                userSession.logoutUser();

                if (to.meta.requiresAuth) {
                    // redirect the user somewhere
                    return {
                        // Will follow the redirection set in /@src/pages/auth/index.vue
                        name: "auth-login",
                        // save the location we were at to come back later
                        query: { redirect: to.fullPath },
                    };
                }
            }
        } else if ((to.meta.requiresAuth || to.meta.roles) && !isLoggedIn) {
            return {
                // Will follow the redirection set in /@src/pages/auth/index.vue
                name: "auth-login",
                // save the location we were at to come back later
                query: { redirect: to.fullPath },
            };
        }
    });

    router.afterEach(() => {
        if (typeof cordova !== "undefined") {
            navigator.splashscreen.hide();
        }
    });
}
