<script setup lang="ts">
import { useModal } from "/@src/lib/stores/modal";
const modal = useModal();
</script>
<template>
    <AppWrapper></AppWrapper>

    <VReloadPrompt app-name="Jarveat" />
    <VModal
        :open="modal.isOpen"
        :title="modal.title"
        actions="center"
        :cancel-label="modal.cancel"
        @close="modal.close()"
    >
        <template #content>
            <VPlaceholderSection :title="modal.subTitle" :subtitle="modal.message" />
        </template>
        <template #action>
            <VButton
                v-if="modal.confirm"
                color="primary"
                raised
                @click="modal.close(true)"
                >{{ modal.confirm }}</VButton
            >
        </template>
    </VModal>
</template>
