<script setup lang="ts">
import { ref, getCurrentInstance } from "vue";
//import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const router = useRouter();
const isShown = ref(!router.currentRoute.value.meta.hideMenu);
const view = ref(null);
const instance = getCurrentInstance();
console.log(instance, view);
router.afterEach((route) => {
    isShown.value = !route.meta.hideMenu;
    view.value._.proxy.$forceUpdate();
    console.log(view.value);
});
console.log(view);
</script>

<i18n lang="yaml"></i18n>

<template>
    <VBarWrapper :shown-bar="isShown">
        <template #content>
            <RouterView ref="view" v-slot="{ Component }">
                <Transition name="from-right">
                    <component :is="Component" />
                </Transition>
            </RouterView>
        </template>

        <template #bar>
            <VButton
                :to="{ name: 'index' }"
                icon="fas fa-home"
                class="menu-bar-button"
                circle
                >Accueil</VButton
            >
            <VButton
                :to="{ name: 'account-book' }"
                class="menu-bar-button"
                icon="fas fa-book-open"
                circle
                >Réservation</VButton
            >
            <VIconButton
                :to="{ name: 'finder' }"
                class="menu-bar-button"
                color="primary"
                icon="fas fa-search-location"
                elevated
                circle
            ></VIconButton>
            <VButton
                icon="fas fa-map-marked"
                class="menu-bar-button"
                circle
                :to="{ name: 'place' }"
                >Rechercher</VButton
            >
            <VButton
                icon="fas fa-user"
                :to="{ path: '/account' }"
                class="menu-bar-button"
                circle
                >Compte</VButton
            >
        </template>
    </VBarWrapper>
</template>

<style lang="scss">
.menu-bar-button.button {
    background-color: transparent !important;
    border-color: transparent !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;

    > .icon {
        height: 33px;
        margin-right: 0 !important;
        margin-left: 0 !important;

        > svg {
            height: 25px;
        }
    }

    > span:not(.icon) {
        margin-top: 3px;
        font-size: 10px;
    }

    &.router-link-exact-active {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0);
        background-color: var(--primary) !important;
        color: var(--white) !important;
    }
}

.menu-bar-button.button:nth-child(3) {
    margin-top: -30px;
    font-size: 28px;
    background-color: var(--primary) !important;

    line-height: 42px;
    height: 60px;
    width: 60px;
    max-width: 60px;
    max-height: 60px;
    border-radius: 50px;
    position: relative;
    top: -5px;
    box-shadow: var(--primary-box-shadow);

    > .icon {
        height: 40px;
        width: 40px;

        > svg {
            height: 25px;
            width: auto;
        }
    }

    &.router-link-active {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0);
        background-color: whitesmoke !important;
        color: var(--dark) !important;
    }
}

@media screen and (min-width: 767px) {
    .menu-bar-button.button:nth-child(3) {
        margin-top: 0;
    }
}
</style>
