const __pages_import_0__ = () => import("/pages/account.vue");
const __pages_import_1__ = () => import("/pages/account/book.vue");
const __pages_import_2__ = () => import("/pages/account/index.vue");
import __pages_import_3__ from "/pages/index.vue";
const __pages_import_4__ = () => import("/pages/[:place]/[:product].vue");
const __pages_import_5__ = () => import("/pages/[:place]/index.vue");
const __pages_import_6__ = () => import("/pages/auth/login.vue");
const __pages_import_7__ = () => import("/pages/booking/book.vue");
const __pages_import_8__ = () => import("/pages/finder/[:session].vue");
const __pages_import_9__ = () => import("/pages/finder/index.vue");
const __pages_import_10__ = () => import("/pages/place/index.vue");

const routes = [{"path":"/account","component":__pages_import_0__,"children":[{"name":"account-book","path":"book","component":__pages_import_1__,"props":true,"meta":{"icon":"lnir lnir-map-marker","title":"backoffice.place.title","roles":["admin","partner","customer"]}},{"name":"account","path":"","component":__pages_import_2__,"props":true,"meta":{"icon":"lnir lnir-map-marker","title":"backoffice.place.title","roles":["admin","partner","customer"]}}],"props":true,"meta":{"roles":["admin","partner","customer"]}},{"name":"index","path":"/","component":__pages_import_3__,"props":true},{"name":":place-:product","path":"/::place/::product","component":__pages_import_4__,"props":true},{"name":":place","path":"/::place","component":__pages_import_5__,"props":true,"meta":{"hideMenu":true}},{"name":"auth-login","path":"/auth/login","component":__pages_import_6__,"props":true,"meta":{"hideMenu":true}},{"name":"booking-book","path":"/booking/book","component":__pages_import_7__,"props":true},{"name":"finder-:session","path":"/finder/::session","component":__pages_import_8__,"props":true},{"name":"finder","path":"/finder","component":__pages_import_9__,"props":true},{"name":"place","path":"/place","component":__pages_import_10__,"props":true}];

export default routes;