<script setup lang="ts">
import { defineProps } from "vue";
import { ExternalScore } from "/@src/models/place";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
export interface ModelProps {
    score: ExternalScore;
}

defineProps<ModelProps>();
</script>
<template>
    <a :href="score.link" target="_blank" class="place-google">
        <span class="place-google-score-star-overlay">
            <span
                class="place-google-score-star"
                :style="{
                    width: (score.score / 5) * 100 + '%',
                }"
            ></span>
        </span>
        <span class="place-google-number-reviews text-ellipsis">{{
            t("customer.places.google.numberOfReviews", {
                numberOfReviews: score.numberOfReviews,
            })
        }}</span>
        <i class="iconify" data-icon="feather:external-link" aria-hidden="true"></i>
    </a>
</template>

<style lang="scss">
.place-google {
    display: flex;
    flex-direction: row;
    margin: 2px 0;

    > .place-google-score {
        margin-right: 3px;
    }

    > .place-google-score-star-overlay {
        margin-top: 3px;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAWCAYAAAA1vze2AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAG+SURBVHgBpVXRUcJAEH0hGf3wQ7CCzNgA2oBoAeLQgGgHfOCMOuo5jvCRj2AFOjZgCdABJZAO4AdnCAnn5oAEQw43+D4ul93Nvdt3uxcgB0TLcUXbcZETBjdQtF0bMhioF98qCdEYcb8tcAMxm1aWU7nj15EDfBIYl/FMFs6RAyy5fkk1hyTJDriS8TJZkSpmySEZj8RANW3JI5mSSwi3CDMoZ4fMyFf4ynAMEaIGHULLIzm9aGopw07QpVFDok22BBNdnZM23afxOFlByjca2XXPwFACn8uXuLpEu00VZNLODBv/guzDD2tC3HprJDHZqyPI+oRtIGVH3N800ubMPhEvTgWmfOdnJQcIjWvx0OxlebXNOJfPGoDBAH9MjSm0Z6rvk8CywYW5V97k1pOYqIMHAwVZ3Y4EOAEXRnJ5sknUwQN2yjyiDj+lCrqiY/BSvuLiGz7JmlQSPfjBUVQ9VKIfMMIzZUsQSXYBDbJLuOVEVWXP10fj+a7ZWYtx6S/8HUQ99bhYZ7ioMvyZibosFQFJQvJkEai4BvXcdEwkwaHqE2CfVCuCnUnUI5PJaFPtJ5sS0YMW3y2uXiWr+AHolp1d2u6aJQAAAABJRU5ErkJggg==);
        background-size: contain;
        height: 12px;
        width: 71px;

        > .place-google-score-star {
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAWCAYAAAA1vze2AAAAqUlEQVR4AWLwySwkGv/YzgworgwwAIphGHqJXWgX6nl6q55o/GHxCX4bUx9hMK9JYwucFSmAubW2ZifED8Q7IXEgcmRqVNDsgBhBrAMSBJEiqwAGAaBRhiDvBk1A3mkRyb2CXSYLlmWFnbArffoMIrjS6qy3Sm/Z/068CPEbyKpKhfCDyA0yNE95MKtRGe9LiSz9O3hKclv6Y76ictQzqbmjyhIkyVi+8wCta5MKDP5AeAAAAABJRU5ErkJggg==);
            background-size: contain;
            height: 12px;

            display: block;
        }
    }

    > .place-google-number-reviews {
        margin-left: 3px;
        color: var(--dark-text);
    }

    > svg {
        margin-left: 3px;
    }
}
</style>
