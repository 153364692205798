<script setup lang="ts">
import { defineProps, watch, ref } from "vue";
import { Place } from "/@src/models/place";
import { useI18n } from "vue-i18n";
import moment from "moment-with-locales-es6";

const { t } = useI18n();
export interface PlaceResultProps {
    place: Place;
    date: any;
}

const props = defineProps<PlaceResultProps>();

const hours = ref([]);

function updateDates() {
    if (!props.date) return;
    let placeHours = null;
    if (props.place.hours) placeHours = props.place.hours[props.date.day()];

    hours.value = [
        props.date.clone().subtract(15, "minutes"),
        props.date,
        props.date.clone().add(15, "minutes"),
    ].filter((date) => {
        if (!placeHours) return true;
        return placeHours.some((placeHour) => {
            const start = moment(placeHour.start, "HH:mm"),
                end = moment(placeHour.end, "HH:mm");
            return start.isSameOrBefore(date) && end.isAfter(date);
        });
    });
}
watch(() => props.date, updateDates);
updateDates();
</script>
<template>
    <VFlex class="result" flex-direction="row" align-items="stretch">
        <ImageFirestore :path="place.picture"></ImageFirestore>
        <h1 v-if="place.userScore" class="place-user-score">{{ place.userScore }}%</h1>

        <VFlex flex-direction="column" align-items="stretch" class="place-content">
            <VFlex
                flex-direction="row"
                justify-content="space-between"
                align-items="center"
            >
                <VTextEllipsis class="place-name">{{ place.name }}</VTextEllipsis>
                <VFlex flex-direction="column" class="place-scores">
                    <Tippy placement="left" trigger="click hover" @click.prevent="">
                        <span class="place-score">
                            <img
                                v-for="n in place.score || 0"
                                :key="n"
                                :src="LogoIcon"
                                alt="score"
                            />
                        </span>
                        <template #content>
                            <div class="v-popover-content is-text">
                                <div
                                    class="popover-body"
                                    v-html="
                                        place.scoreMessage ||
                                        t('customer.places.scoreMessage')
                                    "
                                ></div>
                            </div>
                        </template>
                    </Tippy>
                </VFlex>
            </VFlex>
            <VFlex flex-direction="row">
                <h1 class="place-type">
                    {{
                        place.type ? t("customer.places.filter.types." + place.type) : ""
                    }}
                </h1>
                <p class="place-description">
                    &nbsp;
                    <span v-if="place.priceScore % 1 != 0">
                        <span v-for="n in (place.priceScore || 0.5) - 0.5" :key="n"
                            >€</span
                        >
                        -
                        <span v-for="n in (place.priceScore || 0.5) + 0.5" :key="n"
                            >€</span
                        >
                    </span>
                    <span v-else>
                        <span v-for="n in (place.priceScore || 0.5) - 0.5" :key="n"
                            >€</span
                        >
                    </span>
                    &nbsp;
                </p>
            </VFlex>

            <ScoreGoogle
                v-if="place.externalScore && place.externalScore.google"
                :score="place.externalScore.google"
            ></ScoreGoogle>
            <ScoreTripadvisor
                v-if="place.externalScore && place.externalScore.tripadvisor"
                :score="place.externalScore.tripadvisor"
            ></ScoreTripadvisor>
            <p v-if="place.distance" class="place-description">
                {{ Math.round(place.distance * 100) / 100 }}km
            </p>

            <VFlex flex-direction="row" justify-content="center">
                <VFlex flex-direction="column" align-items="center" class="place-action">
                    <span static>Réserver pour</span>
                    <VFlex flex-direction="row">
                        <VButton
                            v-for="hour in hours"
                            :key="hour.valueOf()"
                            :to="{
                                name: 'booking-book',
                                query: {
                                    place: place.id,
                                    date: hour.valueOf(),
                                },
                            }"
                            color="primary"
                            raised
                            class="ml-2"
                            >{{ hour.format("HH:mm") }}</VButton
                        >
                    </VFlex>
                </VFlex>
            </VFlex>
        </VFlex>
        <span class="divider"></span>
    </VFlex>
</template>

<style lang="scss" scoped>
.result {
    flex-wrap: wrap;

    margin: 30px 0 8px 0;
    color: var(--dark-text-color);
    max-height: none;
    height: 100%;
    padding: 0;
    position: relative;
    width: 100%;

    width: 100%;
    height: 100%;
    color: var(--dark-text-color);
    position: relative;
    display: flex;
    flex-direction: row;

    img {
        width: 150px;
        height: 150px;

        object-fit: cover;
        z-index: 0;
    }

    .place-user-score {
        font-size: 10px;
        color: var(--white);
        border-radius: 30px;
        position: absolute;
        left: 120px;
        top: -10px;
        height: 40px;
        width: 40px;
        background-color: var(--primary);
        text-align: center;
        line-height: 40px;
        vertical-align: middle;
    }

    .place-content {
        padding: 0 15px 15px 15px;
        flex: 1 1 auto;

        width: calc(100% - 150px);
    }

    .place-type {
        text-align: left;
        margin: 5px 0;
        color: var(--dark-text-color);
    }

    .place-name {
        text-align: left;
        font-size: 20px;
        font-weight: bold;
        margin: 5px 0;
        color: var(--dark-text-color);
    }

    .place-description {
        text-align: left;
        margin: 5px 0;
        color: var(--dark-text-color);
        line-height: 20px;
    }
}

.place-action {
    margin: 0px;

    > .button {
        margin: 5px;
    }
}

.place-scores {
    margin: 5px;

    > span {
        text-align: right;

        .place-score {
            font-size: 30px;
            padding: 2px 10px;
            width: 100px;
            margin-bottom: 30px;

            > img {
                height: 20px;
                width: auto;
            }
        }
    }
}
</style>
