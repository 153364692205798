<script setup lang="ts" async>
import { ref } from "vue";
import { useUserSession } from "/@src/lib/stores/userSession";
import Logo from "/@src/assets/logo.svg";
import {
    getFirestore,
    getDocs,
    query,
    where,
    collection,
    doc,
    getDoc,
} from "firebase/firestore";

const firestore = getFirestore();
const userSession = useUserSession();

const places = ref([]);
const user = userSession.getUser();
const placeCollection = collection(firestore, "places");

if (user.value) {
    const bookCollection = collection(firestore, "books");

    const bookQuery = query(
        bookCollection,
        where("customers", "array-contains", user.value.uid)
    );
    const bookDocs = await getDocs(bookQuery);
    await Promise.all(
        bookDocs.docs.map(async (bookDoc) => {
            const data = bookDoc.data();
            const placeDoc = await getDoc(doc(placeCollection, data.place));
            places.value.push(placeDoc.data());
        })
    );
}
const placeIds = ["T9LlCZpnZqT2tYRlMkbb", "IXiqg4ukhJs7q66uaYMl"];
await Promise.all(
    placeIds.map(async (placeId) => {
        const placeDoc = doc(placeCollection, placeId);
        const placeSnapshot = await getDoc(placeDoc);
        places.value.push(placeSnapshot.data());
    })
);

console.log(user, places.value);
</script>

<template>
    <div class="personal-dashboard personal-dashboard-v3">
        <div class="columns">
            <div class="column is-12">
                <div class="dashboard-card is-welcome">
                    <div class="welcome-title">
                        <h3 class="dark-inverted">Bienvenue sur Jarveat</h3>
                        <img class="welcome-logo" :src="Logo" alt="" />
                        <p></p>
                    </div>
                    <div class="welcome-progress">
                        <!--VBillboardJS
                  :options="progressGaugeOptions"
                  @ready="onprogressGaugeReady"
                        /-->
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-content is-active">
            <PlaceResult
                v-for="place in places"
                :key="place.id"
                :place="place"
            ></PlaceResult>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import "../lib/scss/abstracts/mixins";

.is-navbar {
    .personal-dashboard {
        margin-top: 30px;
    }
}

.personal-dashboard-v3 {
    min-height: 100vh;
    .columns {
        max-width: 100%;
        &.is-flex-tablet-p {
            .column {
                &.is-6 {
                    min-width: 50%;
                }
            }
        }
    }

    .dashboard-card {
        @include vuero-l-card;

        font-family: var(--font);

        &:not(:last-child) {
            margin-bottom: 1.5rem;
        }

        &.is-welcome {
            background: var(--widget-grey);
            border: none;
            padding: 40px;
            text-align: center;

            .welcome-title {
                h3 {
                    font-family: var(--font-alt);
                    font-weight: 700;
                    font-size: 2rem;
                    color: var(--dark-text);
                }
            }

            .welcome-logo {
                height: 50px;
                margin-top: 10px;
            }

            .welcome-progress {
                display: flex;
                align-items: center;
                padding: 10px 0;

                .meta {
                    margin-left: 16px;

                    span {
                        display: block;

                        &:first-child {
                            color: var(--light-text);
                            font-size: 0.95rem;
                        }

                        &:nth-child(2) {
                            font-family: var(--font-alt);
                            font-size: 1.1rem;
                            font-weight: 600;
                            color: var(--dark-text);
                        }
                    }
                }
            }

            .button-wrap {
                .v-button {
                    height: 44px;
                    border-radius: 10px;
                }
            }
        }

        &.is-interview {
            &:not(:last-child) {
                margin-bottom: 0.75rem;
            }

            .flex-end {
                svg {
                    height: 18px;
                    width: 18px;
                    color: var(--light-text);
                }
            }
        }
    }

    .picker-widget {
        padding: 40px;
        height: 317px;
    }

    .stats-wrapper {
        display: flex;
        flex-direction: column;
        height: 280px;

        .people {
            margin-top: auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 6px;

            .left {
                span {
                    display: block;
                    font-family: var(--font);

                    &:first-child {
                        font-family: var(--font-alt);
                        font-weight: 600;
                        color: var(--dark-text);
                    }

                    &:nth-child(2) {
                        font-size: 0.9rem;
                        color: var(--light-text);

                        a {
                            font-weight: 500;
                            color: var(--primary);
                        }
                    }
                }
            }
        }
    }
}

.is-dark {
    .personal-dashboard-v3 {
        .dashboard-card {
            @include vuero-card--dark;
        }
    }
}

.picker-widget {
    @include vuero-l-card;

    &.is-straight {
        @include vuero-s-card;
    }

    .calendar {
        color: var(--dark-text);
        font-size: 12px;
        font-family: var(--font);
        width: 100%;
        margin-bottom: 5px;

        thead,
        tbody {
            width: 100%;
        }
    }

    .calendar thead,
    .calendar th {
        color: var(--primary);
        font-weight: 600;
        font-size: 10px;
        text-transform: uppercase;
        text-align: center;
    }

    .calendar td {
        padding: 0.5em 0;
        text-align: center;
        cursor: pointer;
        border-radius: 8px;
    }

    .calendar tbody td:hover {
        background: var(--fade-grey-light-3);
        color: var(--primary);
    }

    .current-day {
        color: var(--primary);
        font-weight: 600;
    }

    .prev-month,
    .next-month {
        color: #cacaca;
    }
}

.is-dark {
    .picker-widget {
        @include vuero-card--dark;

        .calendar {
            color: var(--dark-dark-text);

            thead,
            th {
                color: var(--primary);
            }

            td:hover {
                background: var(--dark-sidebar-light-3);
                color: var(--primary);
            }
        }

        .current-day {
            color: var(--primary);
        }
    }
}

@media only screen and (max-width: 767px) {
    .personal-dashboard-v3 {
        .stats-wrapper {
            height: auto;
        }
    }
}
</style>
